import { render, staticRenderFns } from "./FacturacionRfcI.vue?vue&type=template&id=4cab2c86&"
import script from "./FacturacionRfcI.vue?vue&type=script&lang=js&"
export * from "./FacturacionRfcI.vue?vue&type=script&lang=js&"
import style0 from "./FacturacionRfcI.vue?vue&type=style&index=0&id=4cab2c86&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBar,QIcon,QSpace,QBtn,QTooltip,QCardSection,QSpinnerDots,QSelect,QTable,QTr,QTd,QMarkupTable});qInstall(component, 'directives', {ClosePopup});

import { render, staticRenderFns } from "./FacturacionDetG.vue?vue&type=template&id=2615edba&"
import script from "./FacturacionDetG.vue?vue&type=script&lang=js&"
export * from "./FacturacionDetG.vue?vue&type=script&lang=js&"
import style0 from "./FacturacionDetG.vue?vue&type=style&index=0&id=2615edba&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBar,QIcon,QSpace,QBtn,QTooltip,QCardSection,QChip,QTable,QCheckbox,QInput,QTr,QTd});qInstall(component, 'directives', {ClosePopup});

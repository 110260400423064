<template>
    <div class="q-pa-md">
        <!-- DIALOG -->
        <q-dialog v-model="dialog" persistent transition-show="scale" transition-hide="scale">
            <q-card style="width: 200px">
                <q-card-section>
                    <div class="row justify-center">
                        <span>{{ dialogtext }}</span>
                        <q-spinner-dots color="primary" size="lg" />
                    </div>
                </q-card-section>
            </q-card>
        </q-dialog>

        <!-- DIALOG DE LOS DETALLES -->
        <q-dialog v-model="dialogDetalles" persistent transition-show="scale" transition-hide="scale" maximized>
            <detalles @CloseDialogDetalles="CloseDialogDetalles"></detalles>
        </q-dialog>

        <!-- DIALOG DE LA COMPARATIVA -->
        <q-dialog v-model="dialogComparativa" persistent transition-show="scale" transition-hide="scale">
            <comparativa @CloseDialogDetalles="CloseDialogDetalles"></comparativa>
        </q-dialog>

        <!-- SELECCIONA AÑO Y MES, BOTON DE BUSCAR Y EXPORTAR A EXCEL -->
        <div class="row no-wrap items-center q-mt-md q-pa-sm">
            <q-space />
            <q-select outlined dense v-model="selectedAnio" :options="itemsAnios" label="Año" style="width:80px"
                class="q-mr-xs" />
            <q-select outlined dense v-model="selectedMes" :options="itemsMes" label="Mes" style="width:136px"
                class="q-mr-xs" />
            <q-btn push color="amber-9" @click="GetReporte" icon="mdi-text-box-search-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Consultar</q-tooltip>
            </q-btn>
            <q-btn push color="green-10" @click="ExportExcel" icon="mdi-file-excel-box-outline" rounded flat size="18px"
                padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Exportar Excel</q-tooltip>
            </q-btn>
            <!-- <q-btn push color="blue-10" @click="GetReporteIvaExento" icon="mdi-file-excel-box-outline" rounded flat
                size="18px" padding="xs">
                <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Paso para el ivaexento</q-tooltip>
            </q-btn> -->
            <q-space />
        </div>
        <!-- TABLA -->
        <q-table table-class="primary lighten-5" title="Reporte ISR" :data="dataComprobantes" :columns="columns"
            row-key="dia" :rows-per-page-options="[13]">
            <template v-slot:top>
                <span class="text-body1" content-style="font-size: 20px">Pagos de IVA</span>
                <q-space />
                <q-btn push color="blue-7" @click="OpenNotas(1)" icon="mdi-information-outline" rounded flat size="18px"
                    padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Infomración</q-tooltip>
                </q-btn>
                <q-btn push color="green-14" @click="OpenComparativa()" icon="mdi-compare" rounded flat size="18px"
                    padding="xs">
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                        :offset="[10, 10]">Comparativa</q-tooltip>
                </q-btn>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" :class="'clase-total-' + props.row.año">
                    <q-td key="año" :props="props">{{ props.row.año }}</q-td>
                    <q-td key="mes" :props="props">{{ props.row.mes }}</q-td>

                    <q-td key="baseIvaTrasladado" :props="props">{{ FormatCurrency(props.row.baseIvaTrasladado) }}</q-td>
                    <q-td key="importeIvaTrasladado" :props="props">{{
                        FormatCurrency(props.row.importeIvaTrasladado) }}</q-td>

                    <q-td auto-width>
                        <q-btn size="md" color="primary" rounded flat dense
                            @click="VerDetalles(props.row, 'Comprobantes IVA Trasladado')"
                            icon="mdi-format-list-bulleted">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Detalles</q-tooltip>
                        </q-btn>
                    </q-td>

                    <!-- CASO PARA IVA EXENTO -->
                    <template v-if="banderaExento">
                        <q-td key="porcentajeExento" :props="props">{{ FormatoCantidad(props.row.porcentajeExento) }}</q-td>
                    </template>

                    <q-td key="baseIvaAcreditado" :props="props">{{ FormatCurrency(props.row.baseIvaAcreditado) }}</q-td>
                    <q-td key="importeIvaAcreditado" :props="props">{{ FormatCurrency(props.row.importeIvaAcreditado)
                    }}</q-td>

                    <q-td auto-width>
                        <q-btn size="md" color="primary" rounded flat dense
                            @click="VerDetalles(props.row, 'Comprobantes IVA Acreditado')"
                            icon="mdi-format-list-bulleted">
                            <q-tooltip transition-show="flip-right" transition-hide="flip-left"
                                content-style="font-size: 14px" :offset="[10, 10]">Detalles</q-tooltip>
                        </q-btn>
                    </q-td>

                    <q-td key="ivaRetenido" :props="props">{{ FormatCurrency(props.row.ivaRetenido)
                    }}</q-td>
                    <q-td key="ivaRetenidoAnterior" :props="props">{{ FormatCurrency(props.row.ivaRetenidoAnterior)
                    }}</q-td>

                    <q-td key="ivaCargo" :props="props">{{ FormatCurrency(props.row.ivaCargo) }}</q-td>
                    <q-td key="ivaFavor" :props="props">{{ FormatCurrency(props.row.ivaFavor) }}</q-td>

                    <q-td key="cargoRegistrado" :props="props">{{ FormatCurrency(props.row.cargoRegistrado) }}</q-td>
                    <q-td key="favorRegistrado" :props="props">{{ FormatCurrency(props.row.favorRegistrado) }}</q-td>
                    <q-td key="comparativa" :props="props">{{ FormatCurrency(props.row.comparativa) }}</q-td>
                </q-tr>
            </template>

        </q-table>

         <!-- GRAFICA-->
        <q-card style="width: 100%;" class="full-width q-mt-lg">
            <chart-component :chartData="chartData" :chartTitle="charTitleE"  ></chart-component>
        </q-card>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import * as xlsx from 'xlsx';
import detalles from './ReporteIvaDetalles.vue'
import Comparativa from '../Comparativas/ComparativaIva.vue';
import { QSpinnerCube } from 'quasar';
import ChartComponent from "../Graficas/ChartComponent.vue";

export default {
    components: {
        detalles,
            ChartComponent,
        Comparativa,
    },
    data() {
        return {
            itemsAnios: ['2024','2023', '2022', '2021', '2020', '2019', '2018'],
            itemsMes: [
                { label: 'ENERO', value: 1 },
                { label: 'FEBRERO', value: 2 },
                { label: 'MARZO', value: 3 },
                { label: 'ABRIL', value: 4 },
                { label: 'MAYO', value: 5 },
                { label: 'JUNIO', value: 6 },
                { label: 'JULIO', value: 7 },
                { label: 'AGOSTO', value: 8 },
                { label: 'SEPTIEMBRE', value: 9 },
                { label: 'OCTUBRE', value: 10 },
                { label: 'NOVIEMBRE', value: 11 },
                { label: 'DICIEMBRE', value: 12 },
            ],

            selectedAnio: null,
            selectedMes: null,

            columns: [],
            columnsDefault: [
                { name: 'año', align: 'left', label: 'Año', field: 'año' },
                { name: 'mes', align: 'left', label: 'Mes', field: 'mes' },
                { name: 'baseIvaTrasladado', align: 'right', label: 'Base IVA Trasladado', field: 'baseIvaTrasladado' },
                { name: 'importeIvaTrasladado', align: 'right', label: 'Importe IVA Trasladado', field: 'importeIvaTrasladado' },
                { name: 'accionesT', align: 'left', label: 'Acciones', field: 'accionesT' },
                { name: 'baseIvaAcreditado', align: 'right', label: 'Base IVA Acreditado', field: 'baseIvaAcreditado' },
                { name: 'importeIvaAcreditado', align: 'right', label: 'Importe IVA Acreditado', field: 'importeIvaAcreditado' },
                { name: 'accionesA', align: 'left', label: 'Acciones', field: 'accionesA' },
                { name: 'ivaRetenido', align: 'right', label: 'IVA Retenido', field: 'ivaRetenido' },
                { name: 'ivaRetenidoAnterior', align: 'right', label: 'IVA Retenido Anterior', field: 'ivaRetenidoAnterior' },
                { name: 'ivaCargo', align: 'right', label: 'IVA a Cargo', field: 'ivaCargo', headerClasses: 'bg-primary text-white', classes: 'bg-red-2 text-black text-right ellipsis ' },
                { name: 'ivaFavor', align: 'right', label: 'IVA a Favor', field: 'ivaFavor', headerClasses: 'bg-primary text-white', classes: 'bg-red-2 text-black text-right ellipsis ' },
                { name: 'cargoRegistrado', align: 'right', label: 'Cargo Registrado', field: 'cargoRegistrado', headerClasses: 'bg-green-14 text-white', classes: 'bg-green-2 text-black text-right ellipsis ' },
                { name: 'favorRegistrado', align: 'right', label: 'Favor Registrado', field: 'favorRegistrado', headerClasses: 'bg-green-14 text-white', classes: 'bg-green-2 text-black text-right ellipsis ' },
                { name: 'comparativa', align: 'right', label: 'Comparativa', field: 'comparativa' },
            ],

            columnsExento: [
                { name: 'año', align: 'left', label: 'Año', field: 'año' },
                { name: 'mes', align: 'left', label: 'Mes', field: 'mes' },
                { name: 'baseIvaTrasladado', align: 'right', label: 'Base IVA Trasladado', field: 'baseIvaTrasladado' },
                { name: 'importeIvaTrasladado', align: 'right', label: 'Importe IVA Trasladado', field: 'importeIvaTrasladado' },
                { name: 'accionesT', align: 'left', label: 'Acciones', field: 'accionesT' },
                { name: 'porcentajeExento', align: 'right', label: 'Porcentaje IVA Exento', field: 'porcentajeExento' },
                { name: 'baseIvaAcreditado', align: 'right', label: 'Base IVA Acreditado', field: 'baseIvaAcreditado' },
                { name: 'importeIvaAcreditado', align: 'right', label: 'Importe IVA Acreditado', field: 'importeIvaAcreditado' },
                { name: 'accionesA', align: 'left', label: 'Acciones', field: 'accionesA' },
                { name: 'ivaRetenido', align: 'right', label: 'IVA Retenido', field: 'ivaRetenido' },
                { name: 'ivaRetenidoAnterior', align: 'right', label: 'IVA Retenido Anterior', field: 'ivaRetenidoAnterior' },
                { name: 'ivaCargo', align: 'right', label: 'IVA a Cargo', field: 'ivaCargo', headerClasses: 'bg-primary text-white', classes: 'bg-red-2 text-black text-right ellipsis ' },
                { name: 'ivaFavor', align: 'right', label: 'IVA a Favor', field: 'ivaFavor', headerClasses: 'bg-primary text-white', classes: 'bg-red-2 text-black text-right ellipsis ' },
                { name: 'cargoRegistrado', align: 'right', label: 'Cargo Registrado', field: 'cargoRegistrado', headerClasses: 'bg-green-14 text-white', classes: 'bg-green-2 text-black text-right ellipsis ' },
                { name: 'favorRegistrado', align: 'right', label: 'Favor Registrado', field: 'favorRegistrado', headerClasses: 'bg-green-14 text-white', classes: 'bg-green-2 text-black text-right ellipsis ' },
                { name: 'comparativa', align: 'right', label: 'Comparativa', field: 'comparativa' },
            ],
            dataComprobantes: [],

            //DATOS DE CARGANDO
            dialog: false,
            dialogtext: '',

            //DATOS DE LOS DETALLES
            dialogDetalles: false,

            //DATOS DE LA COMPARATAIVA
            dialogComparativa: false,

            //CASO PARA IVA EXENTO
            banderaExento: false,

            charTitleE: 'Pagos de IVA',
            chartData: null,
        }
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },

        sumTotal() {
            let suma = this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.total, 0);
            let resultado = this.FormatCurrency(suma);
            return resultado;
        },

        rutaAxios() {
            return this.$store.state.rutaMongoStore
        },

        comparativa() {
            return this.$store.state.comparativaStore;
        },

    },
    created() {

    },
    methods: {
        async GetReporte() {
            this.banderaExento = true;

            //EVALUAMOS SI ES IVA EXENTO
            this.$q.loading.show({
                spinner: QSpinnerCube,
                spinnerColor: 'green-8',
                spinnerSize: 140,
                message: 'Evaluando espere..',
            })
            let ivaCargo = await this.GetIvaTrasladado();
            let detalles = ivaCargo[0].detalles;
            let exento = detalles.find(x => x.tipoFactor === 'Exento')
            if (exento) {
                await this.GetReporteIvaExento(ivaCargo);
            } else {
                await this.GetReporteIva(ivaCargo);
            }
        },

        async GetReporteIva(ivaCargo) {
            try {
                this.columns = [...this.columnsDefault]
                this.dataComprobantes = [];
                this.$q.loading.show({
                    spinner: QSpinnerCube,
                    spinnerColor: 'red-8',
                    spinnerSize: 140,
                    message: 'Consultando datos, espere...',
                })

                // let ivaCargo = await this.GetIvaTrasladado();
                let ivaAcreditable = await this.GetIvaAcreditado()
                let ivaRetenido = await this.GetIvaRetenido();
                // let ivaRetenido = [];
                let comparativa = await this.GetComparativa(this.selectedAnio, 'IVA')

                let ObjIva = {}

                for (let x = 0; x < this.selectedMes.value; x++) {
                    ObjIva.año = this.selectedAnio;
                    ObjIva.mes = ivaCargo[x].mes;

                    ObjIva.baseIvaTrasladado = ivaCargo[x].baseIva;
                    ObjIva.importeIvaTrasladado = ivaCargo[x].importeIva;
                    ObjIva.detallesTrasladado = ivaCargo[x].detalles;

                    ObjIva.baseIvaAcreditado = ivaAcreditable[x].baseIva
                    ObjIva.importeIvaAcreditado = ivaAcreditable[x].importeIva
                    ObjIva.detallesAcreditado = ivaAcreditable[x].detalles;

                    ObjIva.ivaRetenidoAnterior = ivaRetenido[x].importeIva
                    ObjIva.ivaRetenido = ivaRetenido[x + 1].importeIva
                    // ObjIva.ivaRetenidoAnterior = 0
                    // ObjIva.ivaRetenido = 0

                    let ivaCargo_ = ivaCargo[x].importeIva;
                    let ivaAcreditado_ = ivaAcreditable[x].importeIva
                    let ivaRetenido_ = ivaRetenido[x].importeIva
                    let ivaRetenidoAnterior_ = ivaRetenido[x + 1].importeIva
                    // let ivaRetenido_ = 0
                    // let ivaRetenidoAnterior_ = 0
                    let calculo = ivaCargo_ - ivaAcreditado_ - ivaRetenido_ + ivaRetenidoAnterior_
                    if (calculo > 0) {
                        ObjIva.ivaCargo = calculo
                        ObjIva.ivaFavor = 0
                    } else {
                        ObjIva.ivaCargo = 0
                        ObjIva.ivaFavor = calculo * -1
                    }

                    //COMPARATIVA
                    ObjIva.cargoRegistrado = comparativa[x].ivaCargo
                    ObjIva.favorRegistrado = comparativa[x].ivaFavor

                    let comparativa_ = (ObjIva.ivaCargo - ObjIva.ivaFavor - ObjIva.cargoRegistrado + ObjIva.favorRegistrado) * -1
                    if (comparativa_ != 0) {
                        comparativa_ = comparativa_ * -1;
                    }
                    ObjIva.comparativa = comparativa_;

                    this.dataComprobantes.push(ObjIva);
                    // console.log(ObjIva)
                    ObjIva = {}
                }

                let objetoTotales = {
                    año: 'Total',
                    mes: '',

                    baseIvaTrasladado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.baseIvaTrasladado, 0),
                    importeIvaTrasladado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.importeIvaTrasladado, 0),
                    detallesTrasladado: [],

                    baseIvaAcreditado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.baseIvaAcreditado, 0),
                    importeIvaAcreditado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.importeIvaAcreditado, 0),
                    detallesAcreditado: [],

                    ivaRetenidoAnterior: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaRetenidoAnterior, 0),
                    ivaRetenido: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaRetenido, 0),
                    ivaCargo: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaCargo, 0),
                    ivaFavor: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaFavor, 0),
                    cargoRegistrado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.cargoRegistrado, 0),
                    favorRegistrado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.favorRegistrado, 0),
                    comparativa: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.comparativa, 0),
                }
                this.dataComprobantes.push(objetoTotales);
                await this.GenerarGrafica( this.dataComprobantes);
                
                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
        },
        
        async GenerarGrafica(data){
                data.pop();
                   const meses = data.map((item) => item.mes);
                    const cargo = data.map((item) => item.ivaCargo);
                    const favor = data.map((item) => item.ivaFavor);

                    let obj1 = {
                        type: 'line',
                        label: 'Cargo (Linea)',
                        borderColor: '#E74747',
                        borderWidth: 2,
                        fill: false,
                        data: cargo
                    }

                    let obj2 = {
                        type: 'bar',
                        label: 'Cargo (Barra)',
                        backgroundColor: '#E74747',
                        data: cargo,
                        borderColor: 'white',
                        borderWidth: 2
                    }

                   
                    let obj5 = {
                        type: 'line',
                        label: 'Favor (Linea)',
                        borderColor: '#66BB6A',
                        borderWidth: 2,
                        fill: false,
                        data: favor
                    }

                    let obj6 = {
                        type: 'bar',
                        label: 'Favor (Barra)',
                        backgroundColor: '#66BB6A',
                        data: favor,
                        borderColor: 'white',
                        borderWidth: 2
                    }
                    let chartDatas = {
                        labels: meses,
                        datasets: []
                    }

                    chartDatas.datasets.push(obj1)
                    chartDatas.datasets.push(obj2)
                    chartDatas.datasets.push(obj5)
                    chartDatas.datasets.push(obj6)
                    this.chartData = { ...chartDatas }
                    console.log(this.chartData)
            },
        
        async GetReporteIvaExento(ivaCargo) {
            try {
                this.columns = [...this.columnsExento];

                this.dataComprobantes = [];
                this.$q.loading.show({
                    spinner: QSpinnerCube,
                    spinnerColor: 'red-8',
                    spinnerSize: 140,
                    message: 'Consultando datos, espere...',
                })

                // let ivaCargo = await this.GetIvaTrasladado();
                let ivaAcreditable = await this.GetIvaAcreditado()
                let ivaRetenido = await this.GetIvaRetenido();
                let comparativa = await this.GetComparativa(this.selectedAnio, 'IVA')

                let ObjIva = {}

                for (let x = 0; x < this.selectedMes.value; x++) {
                    ObjIva.año = this.selectedAnio;
                    ObjIva.mes = ivaCargo[x].mes;

                    ObjIva.baseIvaTrasladado = ivaCargo[x].baseIva;
                    ObjIva.importeIvaTrasladado = ivaCargo[x].importeIva;
                    ObjIva.detallesTrasladado = ivaCargo[x].detalles;

                    ObjIva.porcentajeExento = 0;

                    ObjIva.baseIvaAcreditado = ivaAcreditable[x].baseIva
                    ObjIva.importeIvaAcreditado = ivaAcreditable[x].importeIva
                    ObjIva.detallesAcreditado = ivaAcreditable[x].detalles;

                    ObjIva.ivaRetenidoAnterior = ivaRetenido[x].importeIva
                    ObjIva.ivaRetenido = ivaRetenido[x + 1].importeIva

                    let ivaCargo_ = ivaCargo[x].importeIva;
                    let ivaAcreditado_ = ivaAcreditable[x].importeIva
                    let ivaRetenido_ = ivaRetenido[x].importeIva
                    let ivaRetenidoAnterior_ = ivaRetenido[x + 1].importeIva
                    let calculo = ivaCargo_ - ivaAcreditado_ - ivaRetenido_ + ivaRetenidoAnterior_
                    if (calculo > 0) {
                        ObjIva.ivaCargo = calculo
                        ObjIva.ivaFavor = 0
                    } else {
                        ObjIva.ivaCargo = 0
                        ObjIva.ivaFavor = calculo * -1
                    }

                    //COMPARATIVA
                    ObjIva.cargoRegistrado = comparativa[x].ivaCargo
                    ObjIva.favorRegistrado = comparativa[x].ivaFavor

                    let comparativa_ = (ObjIva.ivaCargo - ObjIva.ivaFavor - ObjIva.cargoRegistrado + ObjIva.favorRegistrado) * -1
                    if (comparativa_ != 0) {
                        comparativa_ = comparativa_ * -1;
                    }
                    ObjIva.comparativa = comparativa_;

                    this.dataComprobantes.push(ObjIva);
                    // console.log(ObjIva)
                    ObjIva = {}
                }

                let objetoTotales = {
                    año: 'Total',
                    mes: '',

                    baseIvaTrasladado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.baseIvaTrasladado, 0),
                    importeIvaTrasladado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.importeIvaTrasladado, 0),
                    detallesTrasladado: [],
                    porcentajeExento: '---',
                    baseIvaAcreditado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.baseIvaAcreditado, 0),
                    importeIvaAcreditado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.importeIvaAcreditado, 0),
                    detallesAcreditado: [],

                    ivaRetenidoAnterior: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaRetenidoAnterior, 0),
                    ivaRetenido: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaRetenido, 0),
                    ivaCargo: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaCargo, 0),
                    ivaFavor: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.ivaFavor, 0),
                    cargoRegistrado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.cargoRegistrado, 0),
                    favorRegistrado: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.favorRegistrado, 0),
                    comparativa: this.dataComprobantes.reduce((acumulador, objeto) => acumulador + objeto.comparativa, 0),
                }
                this.dataComprobantes.push(objetoTotales);

                this.$q.loading.hide()
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
            }
            for (let item of this.dataComprobantes) {
                let detalles = item.detallesTrasladado;
                let exento = detalles.filter(x => x.tipoFactor === 'Exento')
                let sumaTotal = detalles.reduce((acumulador, objeto) => acumulador + objeto.base_, 0)
                let sumaExento = exento.reduce((acumulador, objeto) => acumulador + objeto.base_, 0)
                let porcentaje = sumaExento / sumaTotal
                console.log('Total', sumaTotal);
                console.log('Exento', sumaExento);
                console.log('Porcentaje', porcentaje)
                if (item.mes != '') {
                    item.porcentajeExento = (porcentaje * 100);
                }
            }
        },

        async GetIvaTrasladado() {
            try {
                this.dialogtext = 'Consultando IVA a Cargo ...'
                let fechaI = this.selectedAnio + '-' + '01' + '-01';
                let fechaF = this.selectedAnio + '-' + this.selectedMes.value + '-01';

                let response = await axios.get(this.rutaAxios + 'Ingresos/GetReporteIvaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetIvaAcreditado() {
            try {
                this.dialogtext = 'Consultando IVA Acreditable ...'
                let fechaI = this.selectedAnio + '-' + '01' + '-01';
                let fechaF = this.selectedAnio + '-' + this.selectedMes.value + '-01';

                let response = await axios.get(this.rutaAxios + 'Gastos/GetReporteIvaAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async GetIvaRetenido() {
            try {
                this.dialogtext = 'Consultando IVA Retenido ...'
                let añoSel = this.selectedAnio - 1
                let fechaI = añoSel + '-' + '12' + '-01';
                let fechaF = this.selectedAnio + '-' + this.selectedMes.value + '-01';

                let response = await axios.get(this.rutaAxios + 'Gastos/GetReporteIvaRetenidoAsync/erp_' + this.token.rfc + '/' + fechaI + '/' + fechaF);
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async VerDetalles(item, cabecera) {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'red-8', spinnerSize: 140, message: 'Consultando información...', })
            const nombresMeses = [
                'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
            ];
            const numeroMes = nombresMeses.indexOf(item.mes.toLowerCase()) + 1;
            const año = this.selectedAnio;
            console.log(numeroMes, año);
            let fI = año + '-' + numeroMes  + '-01';
            let fF = año + '-' + numeroMes  + '-01';

            try{
                if(cabecera === "Comprobantes IVA Trasladado"){
                    await this.DetalesEmitidos(fI, fF, cabecera)
                }else if(cabecera === "Comprobantes IVA Acreditado"){
                    await this.DetallesRecibidos(fI, fF, cabecera)
                }
            }catch(error){
                console.log(error);
                this.$q.loading.hide()
            }
        },

        async DetalesEmitidos(fI, fF, cabecera){
            let respuesta = [];
            let response = await axios.get(this.rutaAxios + 'Ingresos/GetReporteIvaDetAsync/erp_' + this.token.rfc + '/' + fI + '/' + fF);
            console.log(response.data)
            respuesta = response.data;
            this.$q.loading.hide()
            this.$store.state.detallesIvaMensualStore.cabecera = cabecera;
            this.$store.state.detallesIvaMensualStore.detalles = respuesta;
            this.dialogDetalles = true;
        },

        async DetallesRecibidos(fI, fF, cabecera){
            let respuesta = [];
            let response = await axios.get(this.rutaAxios + 'Gastos/GetReporteIvaDetAsync/erp_' + this.token.rfc + '/' + fI + '/' + fF);
            console.log(response.data)
            respuesta = response.data;
            this.$q.loading.hide()
            this.$store.state.detallesIvaMensualStore.cabecera = cabecera;
            this.$store.state.detallesIvaMensualStore.detalles = respuesta;
            this.dialogDetalles = true;
        },

        ExportExcel() {
            const workbook = xlsx.utils.book_new();

            const sheetOtros = xlsx.utils.json_to_sheet(this.dataComprobantes);
            xlsx.utils.book_append_sheet(workbook, sheetOtros, 'IVA');

            xlsx.writeFile(workbook, 'PAGOS IVA DE ENERO A ' + this.selectedMes.label + ' ' + this.selectedAnio + '.xlsx');
        },

        FormatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        FormatoCantidad(numero) {
            if (numero === '---') {
                return "";
            } else {
                const numeroConDecimales = Number(numero).toFixed(2);
                return numeroConDecimales.replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }
        },

        ShowNotifsWarning(mensaje) {
            this.$q.notify({
                progress: true,
                message: mensaje,
                type: 'warning',
                position: 'top-right',
                actions: [
                    { label: 'Cerrar', color: 'white', handler: () => { /* ... */ } }
                ]
            })
        },

        async OpenComparativa() {
            if (!this.selectedAnio) {
                this.ShowNotifsWarning('Seleccione el año');
                return;
            }

            this.dialog = true;
            this.dialogtext = 'Consultando datos ...'
            let respuesta = {}

            this.comparativa.textoComparativa = 'Comparativa Pagos de IVA';
            this.comparativa.año = this.selectedAnio;
            this.comparativa.tipo = 'IVA';

            respuesta = await this.GetComparativa(this.selectedAnio, 'IVA');

            this.comparativa.comparativa.eneroCargo = respuesta[0].ivaCargo;
            this.comparativa.comparativa.febreroCargo = respuesta[1].ivaCargo;
            this.comparativa.comparativa.marzoCargo = respuesta[2].ivaCargo;
            this.comparativa.comparativa.abrilCargo = respuesta[3].ivaCargo;
            this.comparativa.comparativa.mayoCargo = respuesta[4].ivaCargo;
            this.comparativa.comparativa.junioCargo = respuesta[5].ivaCargo;
            this.comparativa.comparativa.julioCargo = respuesta[6].ivaCargo;
            this.comparativa.comparativa.agostoCargo = respuesta[7].ivaCargo;
            this.comparativa.comparativa.septiembreCargo = respuesta[8].ivaCargo;
            this.comparativa.comparativa.octubreCargo = respuesta[9].ivaCargo;
            this.comparativa.comparativa.noviembreCargo = respuesta[10].ivaCargo;
            this.comparativa.comparativa.diciembreCargo = respuesta[11].ivaCargo;

            this.comparativa.comparativa.eneroFavor = respuesta[0].ivaFavor;
            this.comparativa.comparativa.febreroFavor = respuesta[1].ivaFavor;
            this.comparativa.comparativa.marzoFavor = respuesta[2].ivaFavor;
            this.comparativa.comparativa.abrilFavor = respuesta[3].ivaFavor;
            this.comparativa.comparativa.mayoFavor = respuesta[4].ivaFavor;
            this.comparativa.comparativa.junioFavor = respuesta[5].ivaFavor;
            this.comparativa.comparativa.julioFavor = respuesta[6].ivaFavor;
            this.comparativa.comparativa.agostoFavor = respuesta[7].ivaFavor;
            this.comparativa.comparativa.septiembreFavor = respuesta[8].ivaFavor;
            this.comparativa.comparativa.octubreFavor = respuesta[9].ivaFavor;
            this.comparativa.comparativa.noviembreFavor = respuesta[10].ivaFavor;
            this.comparativa.comparativa.diciembreFavor = respuesta[11].ivaFavor;

            this.dialogComparativa = true;
            this.dialog = false;
        },

        async GetComparativa(año, tipo) {
            let respuesta = [
                { mes: 'ENERO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'FEBRERO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'MARZO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'ABRIL', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'MAYO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'JUNIO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'JULIO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'AGOSTO', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'SEPTIEMBRE', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'OCTUBRE', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'NOVIEMBRE', importe: 0, ivaCargo: 0, ivaFavor: 0 },
                { mes: 'DICIEMBRE', importe: 0, ivaCargo: 0, ivaFavor: 0 },
            ]
            try {
                let response = await axios.get(this.rutaAxios + 'Comparativa/GetComparativaAsync/erp_' + this.token.rfc + '/' + año + '/' + tipo);
                respuesta = response.data.comparativa
                return respuesta;
            } catch (error) {
                console.log(error)
                return respuesta;
            }
        },

        CloseDialogDetalles() {
            this.dialogComparativa = false;
            this.dialogDetalles = false;
        },
    },
}

</script>
<style>
.clase-total-Total {
    background: rgb(255, 190, 190);
}
</style>

<template>
    <q-layout view="hHh lpR fFf">
        <q-header elevated v-if="logueado">
            <q-toolbar>
                <q-btn flat dense round icon="mdi-home" aria-label="Menu" @click="irInicio" />
                <q-toolbar-title>
                    <div class="text-h6 text-weight-bolder">CONTAGO MI AUDITOR</div>
                </q-toolbar-title>
                <!-- <q-toggle v-model="isDarkMode" label="Modo Oscuro" color="dark"></q-toggle> -->
                <div class="text-h6 q-mr-lg">{{ $store.state.usuario.rfc }}</div>
                <q-btn flat class="q-mx-sm" round dense icon="mdi-download" @click="DescargaHerramienta()" >
                    <q-tooltip transition-show="flip-right" transition-hide="flip-left" content-style="font-size: 14px"
                    :offset="[10, 10]">Obtener herramienta para descargar XML</q-tooltip>
                </q-btn>
                <q-btn flat class="q-mx-sm" round dense icon="mdi-domain" @click="drawerEmpresas = !drawerEmpresas" />
                <q-btn flat class="q-mx-sm" round dense icon="mdi-account" @click="drawerPerfil = !drawerPerfil" />
            </q-toolbar>
        </q-header>

        <!-- DRAWER DERECHO -->
        <q-drawer :width="350" v-model="drawerPerfil" behavior="mobile" side="right" bordered v-if="logueado">
            <drawerPerfil></drawerPerfil>
        </q-drawer>

        <!-- DRAWER EMPRESAS -->
        <q-drawer :width="350" v-model="drawerEmpresas" behavior="mobile" side="right" bordered v-if="logueado">
            <drawerEmpresas></drawerEmpresas>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <q-footer style="height: 30px;" bordered class="bg-dark text-white" v-if="logueado">
            <q-toolbar>
                <q-toolbar-title>
                </q-toolbar-title>
            </q-toolbar>
        </q-footer>
    </q-layout>
</template>
  
<script>
import { ref } from 'vue'
import axios from "axios";
import drawerPerfil from "../DrawerPerfil/DrawerPerfil.vue"
import drawerEmpresas from "../DrawerEmpresas/DrawerEmpresas.vue"

export default {

    components: {
        drawerPerfil,
        drawerEmpresas
    },
    data() {
        return {
            drawerEmpresas:false,
            drawerPerfil: false,
            isDarkMode: false,
            leftDrawerOpen: false,
            drawer: false,
            miniState: true,
        }
    },

    watch: {
        isDarkMode(val) {
            this.$q.dark.set(val);
        }
    },
    computed: {
        logueado() {
            return this.$store.state.usuario;
        },
    },
    created() {
        this.$store.dispatch("autoLogin");
    },
    methods: {
        irInicio() {
            this.$router.push({ name: 'Home' })
        },

        DescargaHerramienta(){
        try {
            const rutaArchivo = '/CargaXml.msi';
            const enlaceTemporal = document.createElement('a');
            enlaceTemporal.href = rutaArchivo;
            enlaceTemporal.download = 'CargaXml.msi';
            document.body.appendChild(enlaceTemporal);
            enlaceTemporal.click();
            document.body.removeChild(enlaceTemporal);
        } catch (error) {
            console.log(erro)
        }
        },
    }
}
</script>
  
<template>
    <q-page class=" q-pa-lg ">
        <div class="row	justify-end">
            <div class="col-12 col-md-2">
                <q-select label="Tipo" filled dense v-model="tipo" :options="['Emisor','Receptor']" />
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="fehaIMasked" filled label="Fecha Inicial" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaI" @input="UltimoDiaMes">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>
                </q-input>
            </div>
            <div class="col-12 col-md-2">
                <q-input v-model="fehaFMasked" filled label="Fecha Final" name="event" outlined dense>
                    <template v-slot:before>
                        <q-icon name="event" color="primary" />
                    </template>
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                        <q-date v-model="fechaF">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat />
                            </div>
                        </q-date>
                    </q-popup-proxy>

                </q-input>
            </div>
            <div class="col-12 col-md-1 text-right">
                <q-btn round color="primary" icon="mdi-magnify" @click="consultar()">
                    <q-tooltip>
                        Buscar
                    </q-tooltip>
                </q-btn>
                <q-btn class="q-ml-sm" round color="green" icon="mdi-microsoft-excel" @click="ExportExcel()">
                    <q-tooltip>
                        Exportar
                    </q-tooltip>
                </q-btn>
            </div>
        </div>
        <q-table :filter="filter" class="shadow-0 q-mt-md" title="Lista de 69B" :data="itemsLista" :columns="columns"
            row-key="rfc" :loading="loadingTabla">
            <template v-slot:top-right>
                <q-input filled dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:append>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </template>
            <template v-slot:loading>
                <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td key="rfc" :props="props">{{ props.row.rfc }}</q-td>
                    <q-td key="nombre" :props="props">{{ props.row.nombre }}</q-td>
                    <q-td key="listaB" :props="props">{{ props.row.listaB }}</q-td>
                    <q-td key="localizado" :props="props">{{ props.row.localizado }}</q-td>
                    <q-td key="fecha_publicacion" :props="props">{{ props.row.fecha_publicacion }}</q-td>
                </q-tr>
            </template>
        </q-table>
    </q-page>
</template>
<script>
    import axios from "axios";
    import moment from 'moment'
    import * as XLSX from 'xlsx';
    import { format } from 'date-fns';
    import { es } from 'date-fns/locale';

    export default {
        components: {},
        data() {
            return {
                tipo: "Emisor",
                fechaI: new Date(),
                fechaF: new Date(),
                itemsLista: [],
                columns: [
                    { name: 'rfc', align: 'center', label: 'RFC', field: 'rfc', classes: 'text-center text-dark  bg-grey-2 ellipsis', headerClasses: 'bg-primary text-white', },
                    { name: 'nombre', align: 'center', label: 'Nombre', classes: 'text-left', field: 'nombre' },
                    { name: 'listaB', align: 'center', label: 'Lista 69B', field: 'listaB' },
                    { name: 'localizado', align: 'center', label: 'No Localizado', field: 'localizado', },
                    { name: 'fecha_publicacion', align: 'center', label: 'Fecha de publicación', field: 'fecha_publicacion', },
                ],
                loadingTabla: false,
                filter: '',
            }
        },
        computed: {
            token() {
                return this.$store.state.usuario;
            },
            fehaIMasked() {
                moment.locale('es-mx');
                return this.fechaI ? moment.utc(this.fechaI).format('DD/MMMM/yyyy') : ''
            },
            fehaFMasked() {
                moment.locale('es-mx');
                return this.fechaF ? moment.utc(this.fechaF).format('DD/MMMM/yyyy') : ''
            },
            rutaAxios() {
                return this.$store.state.rutaMongoStore
            }
        },
        created() {

        },
        methods: {
            async consultar() {
                this.loadingTabla = true

                let fI = moment(this.fechaI).format('YYYY-MM-DD')
                let fF = moment(this.fechaF).format('YYYY-MM-DD')
                try {
                    let response = await axios.get(this.rutaAxios + "Consultas/GetListaB/erp_" + this.token.rfc + "/" + this.tipo + "/" + fI + '/' + fF);
                    console.log(response)
                    this.itemsLista = response.data;
                    this.loadingTabla = false
                } catch (error) {
                    console.log(error);
                    this.loadingTabla = false
                }
            },

            UltimoDiaMes() {
                const fecha = new Date(this.fechaI);
                const ultimoDia = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
                this.fechaF = ultimoDia;
            },

            ExportExcel() {
                let fi_ = new Date(this.fechaI);
                let ff_ = new Date(this.fechaF);

                let fI = format(fi_, 'dd-MMMM-yyyy', { locale: es })
                let fF = format(ff_, 'dd-MMMM-yyyy', { locale: es })

                const workbook = XLSX.utils.book_new();
                const sheetTrabajadores = XLSX.utils.json_to_sheet(this.itemsLista);
                XLSX.utils.book_append_sheet(workbook, sheetTrabajadores, 'LIBRO 1');
                XLSX.writeFile(workbook, 'LISTA DE 69B_' + this.tipo + '_' + fI + ' AL ' + fF + '.xlsx');
            },
        }
    }
</script>
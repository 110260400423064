import { render, staticRenderFns } from "./ConceptosPeriodoDescG.vue?vue&type=template&id=37754cc6&"
import script from "./ConceptosPeriodoDescG.vue?vue&type=script&lang=js&"
export * from "./ConceptosPeriodoDescG.vue?vue&type=script&lang=js&"
import style0 from "./ConceptosPeriodoDescG.vue?vue&type=style&index=0&id=37754cc6&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpinnerDots,QSpace,QInput,QPopupProxy,QDate,QBtn,QTooltip,QTable,QTr,QTd,QMarkupTable});qInstall(component, 'directives', {ClosePopup});
